@font-face {
    font-family: 'Inform';
    src: url('/static/fonts/Inform.ttf');
}
a {
    text-decoration: none;
}
.slick-dots {
    bottom: 40px;
    padding-left: 40px;
    text-align: left;
}
.slick-dots li button:before {
    font-size: 20px;
    color: #000;
}
.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
}
.slick-slide * {
    outline: none;
}
.post {
    color: '#333';
}
.post p {
    margin: 0 0 16px 0;
    line-height: 30px;
}
